<template>
  <div>
    <ConditionalSearch @onSearch="onSearch"></ConditionalSearch>
    <div class="keySearch">
      <div class="search">
        <span> 院校检索： </span>
        <el-input placeholder="请输入院校名称" v-model="name" @keyup.enter.native="doSearch" class="input-with-select">
          <el-button slot="append" @click="doSearch" class="btn">
            <img src="@/assets/img/home/search1.png" alt="" />
          </el-button>
        </el-input>
      </div>
      <div class="search">
        <span> 专业检索： </span>
        <el-input placeholder="请输入专业名称" v-model="specialty_name" @keyup.enter.native="doSearch" class="input-with-select">
          <el-button slot="append" @click="doSearch" class="btn">
            <img src="@/assets/img/home/search1.png" alt="" />
          </el-button>
        </el-input>
      </div>
    </div>
    <div class="content">
      <table class="table">
        <tr>
          <td width="153">院校名称</td>
          <td width="153">专业代码</td>
          <td width="153">专业名称</td>
          <td width="190">招考方向</td>
          <td width="300">包含专业</td>
          <td width="250">选考科目要求</td>
        </tr>

        <tr v-for="(item, i) in tableData" :key="i" class="txt">
          <td>{{ item.name }}</td>
          <td>{{ item.specialty_code }}</td>
          <td>{{ item.specialty_name }}</td>
          <td>{{ item.direction }}</td>
          <td>
            <div v-if="item.contain != null">
              <p style="margin-top: 11px;" v-for="(child, key) in item.contain.split(',')" :key="key">
                {{ child }}
              </p>
            </div>
          </td>
          <td>{{ item.demand }}</td>
        </tr>
      </table>
    </div>

    <!-- 分页 -->
    <div class="main">
      <template>
        <div style="text-align: center; margin-bottom: 20px">
          <el-pagination @current-change="handleCurrentChange" :page-size="page.pageSize" layout=" prev, pager, next"
            :total="page.total">
          </el-pagination>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ConditionalSearch from './components/ConditionalSearch.vue';
export default {
  name: '',
  data() {
    return {
      name: '',
      specialty_name: '',
      tableData: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      check: {
        name: "",
        degree: null,
        nature: null,
        schoolTipId: [],
        school_type_id: [],
        city_id: [],
        subjectCode: []
      },
    };
  },
  components: {
    ConditionalSearch
  },
  
  created() {
    this.$emit('Nav', 1)
    this.getData()
  },
  methods: {
    onSearch(data) {
      this.page.pageNum = 1
      this.check = data
      this.getData()
    },

    doSearch() {
      this.page.pageNum = 1
      this.getData()
    },
    getData() {
      let data = {
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        name: this.name,
        specialty_name: this.specialty_name,
        parent_id: this.check.subjectCode,
        degree: this.check.degree,
        nature: this.check.nature,
        school_type_id: this.check.school_type_id,
        schoolTipId: this.check.schoolTipId,
        city_id: this.check.city_id,

      }
      this.$fecth.post("xuanke/x_specialty/searchSpecialty", data).then((res) => {
        this.tableData = res.lists
        this.page.total = res.total;
      });
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getData()
    }
  },
};
</script>

<style scoped lang='less'>
.keySearch {
  margin-top: 30px;

  .search {
    width: 50%;
    display: inline-block;
  }

  .input-with-select {
    width: 440px;
  }

  .btn {
    width: 80px;
    height: 40px;
    border-radius: 0 5px 5px 0;
    position: relative;
    top: 3px;
    border: 1px solid #e08245;
    background: #e08245;

    img {
      width: 20px;
      position: relative;
      top: -2px;
    }
  }
}

.content {
  margin-top: 30px;
  margin-bottom: 10px;
  border: 1px solid #dfdfdf;
  padding: 20px 0 0 0;
  border-bottom: 0;

  .table {
    text-align: center;
    border-collapse: collapse;

    tr {
      padding: 10px;
    }

    .txt td {
      padding: 20px 0;
      // border: 1px solid #dfdfdf;
      border-bottom: 1px solid #dfdfdf;
    }
  }
}
</style>
